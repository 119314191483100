import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useMemo } from 'react'
import { match } from 'ts-pattern'
import { FindingDetailsDrawerPure } from '../components/finding-details-drawer.tsx'
import { PreviewFixDrawerPure } from '../components/preview-fix-drawer'
import { SeverityBadge } from '../components/severity-badge'
import { SuggestedSeverity } from '../components/suggested-severity.tsx'
import { Pagination, Table } from '../components/table'
import { AnalyzedFinding, Changeset, Fix, TriagedFindingResponse } from '../utils/api-client/user-platform-api-schemas'
import { Theme } from '../utils/higher-order-components/with-theme'
import { DefaultButton } from './default-button.tsx'
import * as styles from './findings-table.css'

type FindingsTablePureProps = {
  pagination: Pagination
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>
  findings?: AnalyzedFinding[]
  totalFindings?: number
  previewFixDrawer?: (props: { findingId: string }) => ReturnType<typeof PreviewFixDrawerPure>
  selectedFinding?: AnalyzedFinding
  selectedFindingMarkdown?: string
  setSelectedFindingId?: React.Dispatch<React.SetStateAction<string | undefined>>
  selectedFindingIdForFix?: string | undefined
  setSelectedFindingIdForFix?: React.Dispatch<React.SetStateAction<string | undefined>>
  selectedFindingFixes?: Fix[]
  selectedFindingChangesets?: Changeset[]
  theme: Theme
}

export const FindingsTablePure: React.FC<FindingsTablePureProps> = ({
  pagination,
  setPagination,
  findings,
  totalFindings,
  selectedFinding,
  selectedFindingMarkdown,
  setSelectedFindingId = () => {},
  selectedFindingIdForFix,
  setSelectedFindingIdForFix = () => {},
  selectedFindingFixes,
  selectedFindingChangesets,
  theme,
}) => {
  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'severity',
          meta: { width: '5%' },
          header: () => <span className={styles.header}>SEVERITY</span>,
          cell: ({ row }) => (
            <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <SeverityBadge variant={row.original.severity} />
              </div>
            </SelectableFindingCell>
          ),
        },
        {
          accessorKey: 'title',
          meta: { width: '26%' },
          header: () => (
            <span className={styles.header} style={{ justifyContent: 'flex-start' }}>
              FINDING
            </span>
          ),
          cell: ({ row }) => (
            <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)}>
              <p className={styles.findingTitle}>
                {row.original.title && row.original.title.length > 52
                  ? row.original.title.slice(0, 49) + '...'
                  : (row.original.title ?? 'No title')}
              </p>
              <p className={styles.findingRule}>{row.original.rule}</p>
            </SelectableFindingCell>
          ),
        },
        {
          accessorKey: 'suggestedStatus',
          meta: { width: '13%' },
          header: () => <span className={styles.header}>SUGGESTED STATUS</span>,
          cell: ({ row }) => {
            if (row.original._embedded?.triage && row.original._embedded.triage.suggested_status) {
              return (
                <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)}>
                  <span className={styles.suggestedStatusCell[row.original._embedded.triage.suggested_status]}>
                    {getSuggestedStatusLabel(row.original._embedded.triage.suggested_status)}
                  </span>
                </SelectableFindingCell>
              )
            }

            return (
              <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)}>
                <span className={styles.suggestedStatusCell.null}>-</span>
              </SelectableFindingCell>
            )
          },
        },
        {
          accessorKey: 'severityUpdate',
          meta: { width: '13%' },
          header: () => <span className={styles.header}>SEVERITY UPDATE</span>,
          cell: ({ row }) => (
            <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)}>
              <SuggestedSeverity
                severity={row.original.severity}
                suggestedSeverity={row.original._embedded?.triage?.suggested_severity ?? null}
              />
            </SelectableFindingCell>
          ),
        },
        {
          accessorKey: 'summary',
          meta: { width: '16%' },
          header: () => <span className={styles.header}>ANALYSIS</span>,
          cell: ({ row }) =>
            row.original._embedded?.triage?.summary ? (
              <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)}>
                <span className={styles.analysisCell}>
                  {row.original._embedded.triage.summary.length > 30
                    ? row.original._embedded.triage.summary.slice(0, 30) + '...'
                    : row.original._embedded.triage.summary}
                </span>
              </SelectableFindingCell>
            ) : (
              <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)}>
                <span className={styles.placeholderCell}>-</span>
              </SelectableFindingCell>
            ),
        },
        {
          accessorKey: 'fix',
          meta: { width: '14%' },
          header: () => (
            <div className={styles.header} style={{ justifyContent: 'flex-start' }}>
              <span style={{ width: '54%', display: 'flex', justifyContent: 'center' }}>FIX</span>
            </div>
          ),
          cell: ({ row }) =>
            row.original._embedded?.fixes.total && row.original._embedded.fixes.total > 0 ? (
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '100%' }}>
                <DefaultButton
                  buttonType={'secondary'}
                  size="small"
                  onClick={() => setSelectedFindingIdForFix(row.original.id)}
                >
                  Preview
                </DefaultButton>
              </div>
            ) : (
              <div className={styles.placeholderCell} style={{ justifyContent: 'flex-start' }}>
                <span style={{ width: '54%', display: 'flex', justifyContent: 'center' }}>-</span>
              </div>
            ),
        },
      ] as ColumnDef<AnalyzedFinding>[],
    [setSelectedFindingId]
  )

  const rowSelection = useMemo(() => {
    if (!selectedFinding) {
      return {}
    }

    return {
      [selectedFinding.id]: true,
    }
  }, [selectedFinding])

  const table = useReactTable<AnalyzedFinding>({
    data: findings ?? [],
    getRowId: finding => finding.id,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection,
    },
  })

  return (
    <>
      {selectedFinding && (
        <FindingDetailsDrawerPure
          finding={selectedFinding}
          markdown={selectedFindingMarkdown}
          handleClose={() => setSelectedFindingId(undefined)}
          theme={theme}
        />
      )}
      {selectedFindingIdForFix && (
        <PreviewFixDrawerPure
          fix={selectedFindingFixes?.[0]}
          changesets={selectedFindingChangesets}
          onClose={() => setSelectedFindingIdForFix(undefined)}
          theme={theme}
        />
      )}
      <Table
        table={table}
        isLoading={findings === undefined}
        selectable={true}
        pagination={pagination}
        setPagination={setPagination}
        tableLabel="Findings"
        rowCount={totalFindings}
      />
    </>
  )
}

const SelectableFindingCell: React.FC<{
  children: React.ReactNode
  handleClick: () => void
}> = ({ children, handleClick }) => (
  <div onClick={handleClick} data-testid="selectable-finding-cell">
    {children}
  </div>
)

export const getSuggestedStatusLabel = (status: TriagedFindingResponse['suggested_status']) => {
  return match(status)
    .with('wont_fix', () => "Won't fix")
    .with('false_positive', () => 'False positive')
    .with('true_positive', () => 'True positive')
    .with('suspicious', () => 'Suspicious')
    .with(null, () => '-')
    .exhaustive()
}
