import { utilities } from '../main.css.ts'
import * as styles from './spinner.css.ts'

export function Spinner({
  label = 'Loading',
  size = 'normal',
}: {
  label?: string
  size?: 'small' | 'normal' | 'large'
}) {
  const sizeMap = {
    small: 16,
    normal: 24,
    large: 128,
  }

  const svgSize = sizeMap[size]

  const strokeWidthMap = {
    small: 2,
    normal: 2,
    large: 8,
  }
  const strokeWidth = strokeWidthMap[size]

  return (
    <>
      <span role="status" aria-label={label} aria-live="polite">
        <svg height={svgSize} width={svgSize} viewBox="0 0 16 16" fill="none" className={styles.spinner}>
          <circle
            cx="8"
            cy="8"
            r="7"
            stroke="currentColor"
            strokeOpacity="0.25"
            strokeWidth={strokeWidth}
            vectorEffect="non-scaling-stroke"
          />
          <path
            d="M15 8a7.002 7.002 0 00-7-7"
            stroke="currentColor"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
          />
        </svg>
      </span>
      <span className={utilities.visuallyHidden}>Loading...</span>
    </>
  )
}
