import * as Accordion from '@radix-ui/react-accordion'
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons'
import * as Tabs from '@radix-ui/react-tabs'
import Markdown from 'react-markdown'
import { useParams } from 'react-router-dom'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialDark, materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { DefaultButton } from '../components/default-button'
import { GhostButton } from '../components/ghost-button'
import { PRStatusBadge } from '../components/pr-status-badge'
import { calculatePullRequestState } from '../components/simple-issues-table'
import LinkIcon from '../components/svg/link-45deg.svg?react'
import { colors, typography } from '../main.css.ts'
import {
  useGetCodemodById,
  useGetDeprecatedChangesetsByCodemodIdDeprecated,
  useGetInstallations,
  useGetPixeebotPullRequests,
  usePostAnalysisMutation,
} from '../utils/api-client/user-platform-api-hooks'
import type { DeprecatedChangeset, DeprecatedChangesets } from '../utils/api-client/user-platform-api-schemas'
import { CodemodWithDescription, PullRequest } from '../utils/api-client/user-platform-api-schemas'
import { ApiError } from '../utils/api-client/user-platform-api.ts'
import { Theme, useTheme } from '../utils/higher-order-components/with-theme'
import { HandleAddToastWithTimeout, useAddToast } from '../utils/higher-order-components/with-toasts'
import * as styles from './generate-single-fix-page.css'

export function GenerateSingleFixPage() {
  const { installationId, repositoryId, analysisId, codemodScheme, codemodName } = useParams()
  if (!installationId || !repositoryId || !analysisId || !codemodScheme || !codemodName)
    throw new Error('Missing required parameters: installationId, repositoryId, analysisId, codemodScheme, codemodName')

  const [origin, language] = codemodScheme.split(':')
  if (!origin || !language) throw new Error('Invalid codemodScheme')

  const { handleAddToastWithTimeout } = useAddToast()
  const codemodId = `${origin}:${language}/${codemodName}`
  const { data: codemod, isPending: isCodemodPending } = useGetCodemodById({ codemodId })
  const {
    mutate: postAnalysisMutation,
    isSuccess,
    isPending,
  } = usePostAnalysisMutation({
    onError: (error: Error) => handleAddToastWithTimeout({ message: <>{error.message}</>, variant: 'error' }),
  })

  const { data: installations } = useGetInstallations()
  const installationOwnerLogin = installations.find(i => i.id === Number(installationId))?.account.login
  const installationsOwnerLogins = installationOwnerLogin ? [installationOwnerLogin] : []
  const { data: pullRequests } = useGetPixeebotPullRequests(installationsOwnerLogins)
  const pullRequest = pullRequests.find(pr => pr.title === codemod?.name)

  if (!codemod && isCodemodPending) return <></>

  if (!codemod && !isCodemodPending) return <>Codemod not found. Is url correct?</>

  if (pullRequest && codemod)
    return (
      <FixPullRequest
        pullRequest={pullRequest}
        codemod={codemod}
        handleAddToastWithTimeout={handleAddToastWithTimeout}
      />
    )

  if (isSuccess && codemod) return <FixIncoming />

  if (!isSuccess && codemod)
    return (
      <FixPreview
        codemod={codemod}
        installationId={installationId}
        repositoryId={repositoryId}
        analysisId={analysisId}
        language={language}
        isPending={isPending}
        postAnalysisMutation={postAnalysisMutation}
      />
    )
}

const FixPullRequest = ({
  pullRequest,
  codemod,
  handleAddToastWithTimeout,
}: {
  pullRequest: PullRequest
  codemod: CodemodWithDescription
  handleAddToastWithTimeout: HandleAddToastWithTimeout
}) => {
  return (
    <div className={styles.pullRequestContainer}>
      <img src="/0-Icon-Trimmed.png" className={styles.pullRequestPixeeIcon} alt="Pixee logo" />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'flex-start' }}>
          <div>
            <h1 className={styles.pullRequestTitle}>
              {pullRequest.title}
              {pullRequest.title.length < 50 ? <br /> : ' '}
              <span className={styles.pullRequestNumber}>#{pullRequest.number}</span>{' '}
              <PRStatusBadge variant={calculatePullRequestState(pullRequest)} />
            </h1>
          </div>
          <p className={styles.pullRequestFixId}>
            <span className={styles.pullRequestFixIdPrefix}>FIX ID: </span>
            {codemod.codemod_id}
          </p>
        </div>
        <div className={styles.pullRequestContent}>
          <div className={styles.pullRequestButtons}>
            <GhostButton
              type="secondary"
              onClick={() => {
                navigator.clipboard.writeText(pullRequest.html_url)
                handleAddToastWithTimeout({ message: <>Link copied to clipboard</>, variant: 'success' })
              }}
            >
              <LinkIcon />
              Copy link
            </GhostButton>
            <DefaultButton buttonType="secondary" href={pullRequest.html_url}>
              View in GitHub
            </DefaultButton>
          </div>
        </div>
      </div>
    </div>
  )
}

const FixIncoming = () => (
  <div className={styles.successContainer}>
    <div className={styles.successContent}>
      <h1 className={styles.successHeading}>Fix incoming</h1>
      <p className={styles.successMessage}>We’ve received your request, and a PR is being generated.</p>
      <p className={styles.successProgress}>
        In progress{''}
        <span className={styles.successProgressBall} />
      </p>
    </div>
  </div>
)

const FixPreview = ({
  codemod,
  installationId,
  repositoryId,
  analysisId,
  language,
  isPending,
  postAnalysisMutation,
}: {
  codemod: CodemodWithDescription
  installationId: string
  repositoryId: string
  analysisId: string
  language: string
  isPending: boolean
  postAnalysisMutation: ReturnType<typeof usePostAnalysisMutation>['mutate']
}) => {
  const { theme } = useTheme()

  return (
    <div className={styles.container}>
      <div className={styles.titleBar}>
        <div className={styles.titleBarLeft}>
          <h1 className={styles.title}>{codemod.name}</h1>
          <p className={styles.subtitle}>
            <span className={styles.subtitlePrefix}>FIX ID: </span> {codemod.codemod_id}
          </p>
        </div>
        <DefaultButton
          onClick={() =>
            postAnalysisMutation({
              installationId: Number(installationId),
              repositoryId: Number(repositoryId),
              codemodId: codemod.codemod_id,
            })
          }
          state={isPending ? 'loading' : 'default'}
        >
          Open PR
        </DefaultButton>
      </div>
      <Tabs.Root defaultValue="overview" className={styles.tabContainer}>
        <Tabs.List className={styles.tabList}>
          <Tabs.Trigger className={styles.tab} value="overview">
            OVERVIEW
          </Tabs.Trigger>
          <Tabs.Trigger className={styles.tab} value="files">
            FILES CHANGED
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content className={styles.tabContent} value="overview">
          {codemod.description ? (
            <MarkdownWrapper theme={theme} language={language} codemodDescription={codemod.description} />
          ) : (
            <p className={styles.description}>No description available for this fix.</p>
          )}
        </Tabs.Content>
        <Tabs.Content className={styles.tabContent} value="files">
          <DeprecatedChangesetBlocks
            installationId={installationId}
            repositoryId={repositoryId}
            analysisId={analysisId}
            codemodId={codemod.codemod_id}
          />
        </Tabs.Content>
      </Tabs.Root>
    </div>
  )
}

export const MarkdownWrapper = ({
  theme,
  language,
  codemodDescription,
}: {
  theme: Theme
  language: string
  codemodDescription: string | undefined | null
}) => {
  return (
    <Markdown
      className={styles.description}
      components={{
        a: ({ children, ...props }) => (
          <a className={styles.markdownLink} target="_blank" rel="noreferrer" {...props}>
            {children}
          </a>
        ),
        code: ({ node, ...props }) => <InlineCode theme={theme} language={language} {...props} />,
        pre: ({ node, ...props }) => <CodeBlock theme={theme} language={language} {...props} />,
      }}
    >
      {codemodDescription?.replace('diff', language)}
    </Markdown>
  )
}

const InlineCode = ({
  language,
  children,
  theme,
  ...props
}: React.HTMLProps<HTMLElement> & { language: string; theme: Theme }) => {
  return (
    <SyntaxHighlighter
      language={language}
      style={theme === 'dark' ? materialDark : materialLight}
      PreTag="code"
      CodeTag="code"
      customStyle={{
        margin: '0',
        padding: '4px 8px',
        borderRadius: '8px',
        backgroundColor: theme === 'dark' ? colors.neutral.neutral80 : colors.neutral.neutral20,
        width: '100%',
      }}
      codeTagProps={{
        style: {
          fontFamily: "'Inconsolata', monospace",
          fontSize: '1rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
          color: theme === 'dark' ? colors.neutral.neutral10 : colors.neutral.neutral90,
        },
      }}
      {...props}
    >
      {children}
    </SyntaxHighlighter>
  )
}

const CodeBlock = ({
  theme,
  language,
  children,
  ...props
}: React.HTMLProps<HTMLElement> & { theme: Theme; language: string }) => {
  // @ts-expect-error
  const code = children?.props?.children
  const codeLines = code?.split('\n')

  const addedLines = codeLines?.reduce(
    (acc: number[], line: string, index: number) => (line.startsWith('+') ? [index + 1, ...acc] : acc),
    []
  )
  const removedLines = codeLines?.reduce(
    (acc: number[], line: string, index: number) => (line.startsWith('-') ? [index + 1, ...acc] : acc),
    []
  )

  return (
    <SyntaxHighlighter
      language={language}
      style={theme === 'dark' ? materialDark : materialLight}
      customStyle={{
        borderRadius: '8px',
        backgroundColor: theme === 'dark' ? colors.neutral.neutral80 : colors.neutral.neutral20,
        color: theme === 'dark' ? colors.neutral.neutral10 : colors.neutral.neutral90,
      }}
      codeTagProps={{
        style: {
          fontFamily: "'Inconsolata', monospace",
          fontSize: '1rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
        },
      }}
      wrapLines={true}
      showLineNumbers={true}
      lineProps={(lineNumber: number) => {
        if (removedLines?.includes(lineNumber))
          return {
            style: {
              backgroundColor: theme === 'dark' ? '#4B1818' : '#FFDCE0',
            },
          } as React.HTMLAttributes<HTMLElement>
        if (addedLines?.includes(lineNumber))
          return {
            style: {
              backgroundColor: theme === 'dark' ? '#2D4F2D' : ' #E0F2E9',
            },
          } as React.HTMLAttributes<HTMLElement>
        return {} as React.HTMLAttributes<HTMLElement>
      }}
      {...props}
    >
      {code}
    </SyntaxHighlighter>
  )
}

const CodeBlock2 = ({
  theme,
  language,
  children,
  ...props
}: React.HTMLProps<HTMLElement> & { theme: Theme; language: string }) => {
  const codeLines = children?.toString().split('\n')

  const addedLines = codeLines?.reduce(
    (acc: number[], line: string, index: number) => (line.startsWith('+') ? [index + 1, ...acc] : acc),
    []
  )
  const removedLines = codeLines?.reduce(
    (acc: number[], line: string, index: number) => (line.startsWith('-') ? [index + 1, ...acc] : acc),
    []
  )

  return (
    <SyntaxHighlighter
      language={language}
      style={theme === 'dark' ? materialDark : materialLight}
      customStyle={{
        borderRadius: '8px',
        margin: '0px',
        padding: '0px',
        backgroundColor: theme === 'dark' ? colors.neutral.neutral80 : colors.neutral.neutral20,
        color: theme === 'dark' ? colors.neutral.neutral10 : colors.neutral.neutral90,
        width: '100%',
      }}
      codeTagProps={{
        style: {
          fontFamily: "'Inconsolata', monospace",
          fontSize: '1rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
        },
      }}
      wrapLines={true}
      showLineNumbers={true}
      lineProps={(lineNumber: number) => {
        if (removedLines?.includes(lineNumber))
          return {
            style: {
              backgroundColor: theme === 'dark' ? '#4B1818' : '#FFDCE0',
            },
          } as React.HTMLAttributes<HTMLElement>
        if (addedLines?.includes(lineNumber))
          return {
            style: {
              backgroundColor: theme === 'dark' ? '#2D4F2D' : ' #E0F2E9',
            },
          } as React.HTMLAttributes<HTMLElement>
        return {} as React.HTMLAttributes<HTMLElement>
      }}
      {...props}
    >
      {children}
    </SyntaxHighlighter>
  )
}

const DeprecatedChangesetBlocks = ({
  installationId,
  repositoryId,
  analysisId,
  codemodId,
}: {
  installationId: string
  repositoryId: string
  analysisId: string
  codemodId: string
}) => {
  const {
    data: changesets,
    error,
    isPending,
    isError,
  } = useGetDeprecatedChangesetsByCodemodIdDeprecated({
    installationId,
    repositoryId,
    analysisId,
    codemodId,
  })
  const { theme } = useTheme()

  if (error instanceof ApiError && error.status === 403)
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <p className={typography.h4}>Access denied</p>
        <p className={typography.paragraphItalic}>
          * It looks like you don't have sufficient permissions to access this repository. Please contact your
          administrator.
        </p>
      </div>
    )
  if (isError)
    return error.message ? (
      <p className={typography.paragraph}>{error.message}</p>
    ) : (
      <p className={typography.paragraph}>Error loading changesets.</p>
    )
  if (isPending && !isError) return <p className={typography.paragraph}>Loading...</p>
  if (changesets.length === 0) return <p className={typography.paragraph}>No changesets found.</p>

  return <DeprecatedChangesetBlocksPure changesets={changesets as DeprecatedChangesets} theme={theme} />
}

export const DeprecatedChangesetBlocksPure = ({
  changesets,
  theme,
}: {
  changesets: DeprecatedChangesets
  theme: Theme
}) => {
  return (
    <Accordion.Root
      type="multiple"
      defaultValue={changesets.map(changeset => changeset.path + changeset.diff.length)}
      className={styles.accordion}
    >
      {changesets.map(changeset => (
        <Accordion.Item
          key={changeset.path + changeset.diff.length}
          value={changeset.path + changeset.diff.length}
          className={styles.accordionItem}
        >
          <Accordion.Header className={styles.accordionHeader}>
            <Accordion.Trigger className={styles.accordionTrigger}>
              <ChevronDownIcon className={styles.chevronDown} />
              <ChevronUpIcon className={styles.chevronUp} />
              {changeset.path}
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content>
            <DeprecatedChangesetBlock changeset={changeset} theme={theme} />
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  )
}

const DeprecatedChangesetBlock = ({ changeset, theme }: { changeset: DeprecatedChangeset; theme: Theme }) => {
  const language = changeset.path.split('.').pop() || 'text'
  const inferredLanguage =
    language === 'java' ? 'java' : language === 'py' ? 'python' : language === 'cs' ? 'csharp' : 'text'

  return (
    <CodeBlock2 theme={theme} language={inferredLanguage}>
      {changeset.diff}
    </CodeBlock2>
  )
}
